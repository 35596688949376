import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { TeaserAttribute, TeaserAttributes } from '@wh/common/chapter/components/TeaserAttributes'
import { Price } from '@bbx/common/types/Price'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { ResultListSaveAdButton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListSaveAdButton'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { isFormattedPrice } from '@wh/common/chapter/lib/formatter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, ReactNode } from 'react'
import { AdInMotionGallery } from './AdInMotionGallery'
import { ResultListAdRowSpacer } from './ResultListAdRowSpacer'

interface ResultListAdInMotionLayoutProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
    heading: string
    subHeader: ReactNode
    teaserAttributes: TeaserAttribute[] | null | undefined
    footer: ReactNode
    price: Price
    className?: string
}

export const ResultListAdInMotionLayout: FunctionComponent<ResultListAdInMotionLayoutProps> = ({
    advertSummary,
    taggingData,
    heading,
    price,
    subHeader,
    footer,
    teaserAttributes,
    className,
}) => {
    const link = getSeoUrl(advertSummary)
    const highlighted = !!getAttributeValue(advertSummary.attributes.attribute, 'RESULT_LIST_STYLE2')

    return (
        <Box
            id={advertSummary.id}
            padding="m"
            borderBottom="owl"
            className={className}
            position="relative"
            css={adFocusHoverStyles(highlighted)}
            testId={`adInMotion-${advertSummary.id}`}
        >
            <AdInMotionGallery advertSummary={advertSummary} taggingData={taggingData} />
            <Box marginTop="s" position="relative">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href={link}
                    color="palette.verydarkgrey"
                    underline="none"
                    tabIndex={-1}
                    testId={`search-result-entry-header-${advertSummary.id}`}
                    onClick={() => {
                        return callActionEvent('search_result_list_adinmotion_click', taggingData, { ad_id: `${advertSummary.id}` })
                    }}
                >
                    <Box display="flex" marginBottom="xs">
                        <Box color="palette.verydarkgrey" display="flex" flexDirection="column" flex={1} minWidth={0}>
                            <Text as="h3" marginBottom="xs" fontSize="l" fontWeight="normal" truncate={true}>
                                {heading}
                            </Text>
                            {subHeader}
                        </Box>
                        <ResultListAdRowSpacer />
                    </Box>
                    <TeaserAttributes
                        testId={`search-result-entry-teaser-attributes-${advertSummary.id}`}
                        teaserAttributes={teaserAttributes}
                        gap={{ phone: 'l', desktop: 'xxl' }}
                    />
                    <Box
                        display="flex"
                        gap={{ phone: 'xs', desktop: 'l' }}
                        flexDirection={{ phone: 'column-reverse', desktop: 'row' }}
                        alignItems={{ desktop: 'flex-end' }}
                        marginTop="xs"
                    >
                        <Box flexGrow={1} minWidth={0}>
                            {footer}
                        </Box>
                        {price.value && isFormattedPrice(price.value) && (
                            <Text
                                fontSize={{ phone: 'xl', desktop: 'xxl' }}
                                fontWeight="bold"
                                lineHeight={1}
                                color="palette.primary.main"
                                flexShrink={0}
                                testId={`search-result-entry-price-${advertSummary.id}`}
                            >
                                {price.value}
                            </Text>
                        )}
                    </Box>
                </ClientRoutingAnchorLink>
                <ResultListSaveAdButton
                    adId={advertSummary.id}
                    adTitle={heading}
                    taggingData={taggingData}
                    testId={`search-result-entry-save-ad-${advertSummary.id}`}
                    position="absolute"
                    right={0}
                    top={0}
                    zIndex="popover"
                />
            </Box>
        </Box>
    )
}
