import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { ResultListPagination } from '@bbx/search-journey/sub-domains/search/components/common/result-list/Pagination/ResultListPagination'
import { Button } from '@wh-components/core/Button/Button'
import { Sorting } from '@bbx/search-journey/sub-domains/search/components/common/common/Sorting/Sorting'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { css } from 'styled-components'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

interface ResultListActionBarProps {
    totalPages: number
    currentPage: number
    currentSearchResult: SearchResult
    triggerSearch: SearchCallback
    regularNonStickyHeaderVisibilityTrackingRef: React.RefObject<HTMLDivElement>
    setShowNavigatorSidebar: (value: boolean) => void
    taggingData: TaggingData
    bubbles: ReactNode
}

export const ResultListActionBar: FunctionComponent<ResultListActionBarProps> = ({
    currentSearchResult,
    triggerSearch,
    regularNonStickyHeaderVisibilityTrackingRef,
    totalPages,
    currentPage,
    setShowNavigatorSidebar,
    taggingData,
    bubbles,
}) => (
    <Box
        display="flex"
        flexDirection={{ phone: 'column-reverse', tablet: 'column' }}
        paddingHorizontal={{ phone: 'm', tablet: 0 }}
        backgroundColor={{ phone: 'palette.babyseal', tablet: 'transparent' }}
        ref={regularNonStickyHeaderVisibilityTrackingRef}
    >
        <Box
            paddingBottom="s"
            borderBottom={{ tablet: 'owl' }}
            css={css`
                &:empty {
                    padding: 0;
                }
            `}
        >
            {bubbles}
        </Box>
        <Box
            testId="result-list-action-bar"
            display={{ phone: 'flex', tablet: currentSearchResult.rowsFound > 0 ? 'flex' : 'none' }}
            justifyContent="space-between"
            paddingVertical="s"
            borderBottom={{ tablet: 'owl' }}
        >
            <Box display={{ phone: 'none', tablet: 'block' }}>
                <ResultListPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    searchResult={currentSearchResult}
                    onSearch={triggerSearch}
                    testId="pagination-top"
                />
            </Box>
            <Box display={{ tablet: 'none' }} flexBasis="50%" marginRight="s">
                <Button
                    size="small"
                    width="100%"
                    testId="filter-button-small"
                    onClick={() => {
                        setShowNavigatorSidebar(true)
                        return callActionEvent('search_result_list_filter_small_click', taggingData)
                    }}
                >
                    Filtern
                </Button>
            </Box>
            <Sorting
                onSearch={triggerSearch}
                sortOrderList={currentSearchResult.sortOrderList}
                taggingData={taggingData}
                testId="sorting-select"
            />
        </Box>
    </Box>
)
