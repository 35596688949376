import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { TeaserAttribute, TeaserAttributes } from '@wh/common/chapter/components/TeaserAttributes'
import { Price } from '@bbx/common/types/Price'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { ResultListSaveAdButton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListSaveAdButton'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { baseFontSize } from '@wh-components/core/theme/fontSizeHelper'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { isFormattedPrice } from '@wh/common/chapter/lib/formatter'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { inverseVerticalIdMap } from '@wh/common/chapter/types/verticals'
import React, { Fragment, FunctionComponent, ReactNode, useCallback, useRef } from 'react'
import { css } from 'styled-components'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import icon360 from '@bbx/static_hashed/img/adDetail/icon_360.png'
import { AdBadges } from '@bbx/search-journey/common/components/AdvertSummary/AdBadges'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'
import { BumpedTooltip } from '@bbx/search-journey/common/components/BumpedTooltip/BumpedTooltip'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

interface ResultListAdRowLayoutProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
    headingLineClamp: number
    subHeader: ReactNode
    teaserAttributes: TeaserAttribute[] | null | undefined
    footer: ReactNode
    price: Price
    className?: string
    onClickTaggingAction?: () => Promise<void>
}

export const ResultListAdRowLayout: FunctionComponent<ResultListAdRowLayoutProps> = (props) => {
    const isPolePosition = getAttributeValue(props.advertSummary.attributes.attribute, 'REFERER') === 'pp'

    if (isPolePosition) {
        return <ResultListAdRowLayoutPolePosition {...props} />
    } else {
        return <ResultListAdRowLayoutRegular {...props} />
    }
}

const ResultListAdRowLayoutRegular: FunctionComponent<ResultListAdRowLayoutProps> = (props) => {
    return <ResultListAdRowLayoutCommon {...props} />
}

const ResultListAdRowLayoutPolePosition: FunctionComponent<ResultListAdRowLayoutProps> = (props) => {
    const polePositionVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const polePositionVisibilityCallback = useCallback(() => {
        logBdsEvent(props.advertSummary.id, 'pole-position-viewed')
        callActionEvent('search_result_list_pole_position_view', props.taggingData, { ad_id: `${props.advertSummary.id}` })
    }, [props.advertSummary.id, props.taggingData])
    useIntersectionObserver(
        polePositionVisibilityTrackingRef,
        { triggerOnce: true, threshold: 0.5 },
        [props.advertSummary],
        polePositionVisibilityCallback,
    )

    return (
        <ResultListAdRowLayoutCommon
            {...props}
            onClickTaggingAction={() => {
                props.onClickTaggingAction?.()
                callActionEvent('search_result_list_pole_position_click', props.taggingData, { ad_id: `${props.advertSummary.id}` })
                return logBdsEvent(props.advertSummary.id, 'pole-position-clicked')
            }}
            polePositionVisibilityTrackingRef={polePositionVisibilityTrackingRef}
        />
    )
}

interface ResultListAdRowLayoutCommonProps extends ResultListAdRowLayoutProps {
    polePositionVisibilityTrackingRef?: React.RefObject<HTMLDivElement>
    onClickTaggingAction?: () => Promise<void>
    className?: string
}

const ResultListAdRowLayoutCommon: FunctionComponent<ResultListAdRowLayoutCommonProps> = ({
    advertSummary,
    taggingData,
    headingLineClamp,
    subHeader,
    teaserAttributes,
    footer,
    price,
    polePositionVisibilityTrackingRef,
    onClickTaggingAction,
    className,
}) => {
    const link = getSeoUrl(advertSummary)
    const image = advertSummary.advertImageList.advertImage[0]
    const highlighted = !!getAttributeValue(advertSummary.attributes.attribute, 'RESULT_LIST_STYLE2')
    const isHouse = getAttributeValue(advertSummary.attributes.attribute, 'PROPERTY_TYPE_HOUSE') === 'true'
    const virtualViewLink = getAttributeValue(advertSummary.attributes.attribute, 'VIRTUAL_VIEW_LINK')
    const isBumped = getAttributeValue(advertSummary.attributes.attribute, 'IS_BUMPED') === '1'
    const oldPrice = getAttributeValue(advertSummary.attributes.attribute, 'OLD_PRICE_FOR_DISPLAY')
    const isMotor = advertSummary.verticalId === 3

    return (
        <Box position="relative">
            <Box
                id={advertSummary.id}
                borderBottom="owl"
                ref={polePositionVisibilityTrackingRef}
                className={className}
                position="relative"
                css={adFocusHoverStyles(highlighted)}
            >
                <ClientRoutingAnchorLink
                    height={{ desktop: `${182 / baseFontSize}rem` }}
                    display="flex"
                    flexDirection={{ desktop: 'column' }}
                    flexWrap="wrap"
                    padding="m"
                    type="anchor"
                    href={link}
                    color="palette.verydarkgrey"
                    underline="none"
                    onClick={onClickTaggingAction}
                    id={`search-result-entry-header-${advertSummary.id}`}
                    testId={`search-result-entry-header-${advertSummary.id}`}
                    aria-labelledby={`search-result-entry-header-${advertSummary.id}`}
                    css={css`
                        outline: 0;
                    `}
                >
                    <Box
                        order={{ phone: 2, desktop: 1 }}
                        marginRight={{ phone: 's', tablet: 'm' }}
                        position="relative"
                        height={{ desktop: '100%' }}
                    >
                        <AspectRatioBox width={{ phone: 175, tablet: 200 }} ratio={4 / 3} backgroundColor="palette.babyseal">
                            {image ? (
                                <ResponsiveImage src={image.mainImageUrl} alt={image?.description ?? ''} objectFit="contain" />
                            ) : (
                                <Box>
                                    <PlaceholderImage
                                        vertical={inverseVerticalIdMap[advertSummary.verticalId]}
                                        adType={inverseAdTypeIdMap[advertSummary.adTypeId]}
                                        isHouse={isHouse}
                                    />
                                </Box>
                            )}
                        </AspectRatioBox>
                        <AdBadges
                            advertStatus={advertSummary.advertStatus}
                            attributes={advertSummary.attributes.attribute}
                            position="absolute"
                            top="xs"
                            left="xs"
                        />
                        <Fragment>{virtualViewLink ? <TourOverlay /> : undefined}</Fragment>
                        {advertSummary.upsellingOrganisationLogo && (
                            <ResponsiveImage
                                src={advertSummary.upsellingOrganisationLogo}
                                alt={`${advertSummary.advertiserInfo?.label ?? 'Anbieter'} Logo`}
                                display={{ tablet: 'none' }}
                                maxHeight={30}
                                maxWidth={75}
                                position="absolute"
                                bottom={0}
                                right={0}
                                objectFit="contain"
                            />
                        )}
                    </Box>
                    <Box
                        order={{ phone: 1, desktop: 2 }}
                        width={{ phone: '100%', desktop: 'calc(100% - 200px - 16px)' }}
                        marginBottom="s"
                        display="flex"
                    >
                        <Box color="palette.verydarkgrey" display="flex" flexDirection="column" minWidth={0} flex={1}>
                            <Text as="h3" fontSize="l" fontWeight="normal" marginBottom="xs" lineClamp={headingLineClamp}>
                                {isBumped && <BumpedTooltip />}
                                {advertSummary.description}
                            </Text>
                            {subHeader}
                        </Box>
                        {/* spacing to truncate header text */}
                        <Box marginLeft="46px" />
                    </Box>
                    <Box
                        order={3}
                        width={{ phone: 'calc(100% - 175px - 8px)', tablet: 'calc(100% - 200px - 16px)' }}
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                    >
                        <TeaserAttributes
                            teaserAttributes={teaserAttributes}
                            testId={`search-result-entry-teaser-attributes-${advertSummary.id}`}
                            flexDirection={{ phone: 'column', desktop: 'row' }}
                            gap={{ phone: '0', desktop: 'xxl' }}
                            valueFontSize={{ phone: 'm', tablet: 'xl' }}
                            postFixFontSize={{ phone: 's', tablet: 'm' }}
                        />
                        <Box
                            display="flex"
                            flexDirection={{ phone: 'column-reverse', desktop: 'row' }}
                            alignItems={{ desktop: 'flex-end' }}
                            justifyContent="space-between"
                            flexGrow={1}
                            marginTop={{ phone: 'xs', desktop: 's' }}
                        >
                            <Box minWidth={0} overflow="hidden">
                                {footer}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection={{ phone: 'column-reverse', desktop: 'column' }}
                                alignItems={{ desktop: 'flex-end' }}
                            >
                                {oldPrice && isMotor && (
                                    <Text
                                        fontSize="m"
                                        color="palette.elephant"
                                        css={css`
                                            text-decoration: line-through;
                                            white-space: nowrap;
                                        `}
                                    >
                                        {oldPrice}
                                    </Text>
                                )}
                                {price.value && isFormattedPrice(price.value) && (
                                    <Text
                                        fontSize={{ phone: 'xl', desktop: 'xxl' }}
                                        fontWeight="bold"
                                        lineHeight={1}
                                        color="palette.primary.main"
                                        flexShrink={0}
                                        css={css`
                                            white-space: nowrap;
                                        `}
                                        marginLeft={{ desktop: 'm' }}
                                        testId={`search-result-entry-price-${advertSummary.id}`}
                                    >
                                        {price.value}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </ClientRoutingAnchorLink>
            </Box>
            <ResultListSaveAdButton
                adId={advertSummary.id}
                adTitle={advertSummary.description}
                taggingData={taggingData}
                testId={`search-result-entry-save-ad-${advertSummary.id}`}
                position="absolute"
                right="m"
                top="m"
                zIndex="popover"
            />
        </Box>
    )
}

const TourOverlay: FunctionComponent = () => (
    <Box position="absolute" bottom="xs" left="xs">
        <ResponsiveImage src={icon360} alt="360 Grad Tour verfügbar" display="block" />
    </Box>
)
