import React, { FunctionComponent } from 'react'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { FlexboxProps } from '@wh-components/system/flexbox'
import { ResponsiveValue, SystemValue } from '@wh-components/system'
import { Text, TextProps } from '@wh-components/core/Text/Text'
import { Box } from '@wh-components/core/Box/Box'
import { Property } from 'csstype'

export interface TeaserAttribute {
    value?: string | null | undefined
    postfix?: string | null
    prefix?: string | null
}

interface TeaserAttributesProps extends Required<TestProps>, SpaceProps, FlexboxProps, Omit<TextProps, 'testId'> {
    teaserAttributes: TeaserAttribute[] | undefined | null
    count?: number
    gap?: ResponsiveValue<Property.Gap>
    valueFontSize?: ResponsiveValue<SystemValue>
    postFixFontSize?: ResponsiveValue<SystemValue>
}

export const TeaserAttributes: FunctionComponent<TeaserAttributesProps> = ({
    testId,
    teaserAttributes,
    count = 3,
    valueFontSize = 'xl',
    postFixFontSize = 'm',
    gap = { phone: 'l', tablet: 'xxl', desktop: 'xxxl' },
    ...props
}) => {
    if (!teaserAttributes || teaserAttributes.length === 0) {
        return null
    }

    return (
        <Box display="flex" gap={gap} testId={testId} {...props}>
            {teaserAttributes.slice(0, count).map((teaserAttribute, index) => (
                <Text
                    as="div"
                    key={`${teaserAttribute.value}-${index}`}
                    flexShrink={index < count - 1 ? 0 : undefined}
                    lineClamp={1}
                    testId={`${testId}-${index}`}
                    maxWidth="100%"
                >
                    {teaserAttribute.prefix && <Text fontSize={postFixFontSize}>{teaserAttribute.prefix} </Text>}
                    <Text fontSize={valueFontSize} fontWeight="bold">
                        {teaserAttribute.value}
                    </Text>
                    {teaserAttribute.postfix && <Text fontSize={postFixFontSize}> {teaserAttribute.postfix}</Text>}
                </Text>
            ))}
        </Box>
    )
}
